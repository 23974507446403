<template>
  <article class="app-page">
    <HomeAddressActions @onBack="$router.go(-1)" :dataSource="dataSource"/>
  </article>
</template>

<script>
import HomeAddressActions from './HomeAddressActions.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeAddressPage',
  components: {
    HomeAddressActions
  },
  mounted() {
    if (this.addressId && this.addresses.length === 0) {
      this.getAddressesData()
    }
  },
  methods: {
    ...mapActions(['getAddressesData']),
  },
  computed: {
    ...mapGetters(['addresses']),
    addressId() {
      return this.$route.params.id
    },
    dataSource() {
      if (!this.addressId) return {}
      if (this.addresses.length === 0 ) return {}
      return this.addresses.find(item => item.id == this.addressId)
    }
  },
}
</script>
